<template>
  <div class=" max-w-2xl mx-auto p-12">
    <div class=" border-b">
    <div>
      <div class="border-2 p-6 border-green-500  rounded-md">
        <div class=" text-lg black95 flex items-center">
          <div class="checkmark mr-4"></div>
          <div>
            Great, beneficiary AAAA,was successfully created!
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="bookSrc" alt="">
        <div class=" black95 font-600 text-base">Beneficiary Details</div>
      </div>
      <div class=" grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>Name:</div>
        <div>{{beneficiaryFormData.name}}</div>
        <div>Email:</div>
        <div>{{beneficiaryFormData.email}}</div>
        <div>Address:</div>
        <div>{{beneficiaryFormData.beneficiary_address}}</div>
        <div>Country:</div>
        <div>{{beneficiaryFormData.beneficiary_country}}</div>
      </div>
    </div>
    <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="concilSrc" alt="">
        <div class=" black95 font-600 text-base">Beneficiary Bank Details</div>
      </div>
      <div class=" grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>Currency:</div>
        <div>{{beneficiaryFormData.currency}}</div>
      </div>
      <div>
      <div class="pl-4">Priority:</div>
        <div class=" grid grid-cols-2 gap-1 mt-2 px-12">
        <div>Beneficiary Type:</div>
        <div class=" capitalize">{{beneficiaryFormData.beneficiary_type}}</div>
        <div>Address:</div>
        <div>{{beneficiaryFormData.beneficiary_address}}</div>
        <div>City:</div>
        <div>{{beneficiaryFormData.beneficiary_city}}</div>
        <div>Beneficiary Country:</div>
        <div>{{beneficiaryFormData.beneficiary_country}}</div>
        <div>Name:</div>
        <div>{{beneficiaryFormData.bank_account_holder_name}}</div>
        <div>BIC/SWIFT:</div>
        <div>{{beneficiaryFormData.bic_swift}}</div>
        <div>Account Number:</div>
        <div>{{ beneficiaryFormData.account_number }}</div>
      </div>
      </div>
  </div>
   <div class="flex justify-evenly pt-4  max-w-3xl">
     <a-button @click="handleBackToList" type="primary" >
        返回收款人列表
      </a-button>
      <!-- <a-button @click="handleCreatePay" type="primary" >
        支付
      </a-button> -->
    </div>
  </div>
</template>

<script>
import rotateSrc from '@/assets/icon/rotate.png'
import moneyExchangeSrc from '@/assets/icon/money-exchange.png'
import concilSrc from '@/assets/icon/council.png'
import bookSrc from '@/assets/icon/book.png'

import fileSrc from '@/assets/icon/file-text-fill.png'
export default {
  created () {
    this.$emit('update:current', 3)
  },
  data () {
    return {
      rotateSrc,
      concilSrc,
      bookSrc,
      moneyExchangeSrc,
      fileSrc
    }
  },
  methods: {
    handleBackToList () {
      this.$router.push('/user/beneficiary')
    },
    handleCreatePay () {
      // this.$router.push('/user/beneficiary/add/step-four')
    }
  },
  computed: {
    beneficiaryFormData () {
      return this.$store.state.ccBeneficiary.formData
    }
  }
}
</script>

<style scoped>
.checkmark {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
}

.checkmark::after {
  content: "";
  display: block;
  width: 12px;
  height: 20px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 10%;
  left: 50%;
}
.grid_layout{
  grid-template-columns: 1fr 1fr;
}
</style>
